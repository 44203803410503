import React, {createRef, useEffect, useMemo, useState, useRef, useContext} from "react";
import './chapters.scss';
import ZhuanlanRender from "zhuanlan-render";
import httpclient from "../../service/httpclient";
import {useLocation, useParams} from "react-router-dom";
import {Comment, Modal, Popover, Spin, Tooltip} from "antd";
import PopModal from "../../components/modal/modal";
import wx from 'weixin-js-sdk'
import PopDrawer from "../../components/Drawer/drawer";
import {StarOutlined,WechatOutlined,CommentOutlined,MenuUnfoldOutlined,MenuFoldOutlined} from '@ant-design/icons';
import LoginDrawer from "../../components/LoginDrawer/logindrawer";
import SideMenu from "../../components/SideMenu/sidemenu";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Sidebar from "../../components/sidebar/sidebar";
import useResizeObserver from "@react-hook/resize-observer";
import Comments from "../zhuanlans/zhuanlan_detail_content/comments/comments";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";
import {UserContext} from "../../App";


function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Chapters() {
    const scrollRef = useRef();
    let query = useQuery();
    const [target, setTarget] = React.useState();
    const size = useSize(target);
    const isWxBrowser = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) == 'micromessenger';
    }
    const comment_id = query.get('scrollToComment');
    const default_expand_id = query.get('defaultExpand');
    const [chaptersData,setChaptersData] = useState(null);
    const [chaptersContent,setChaptersContent] = useState(null);
    const [zhuanlanData,setZhuanlanData] = useState(null);
    const [isPreview,setIsPreview] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const [sidebarVisible,setSidebarVisible] = useState(false);
    const [giftCnt,setGiftCnt] = useState(null);
    const {userInfo, updateUserInfo} = useContext(UserContext);
    const chaptersid= useParams().chaptersid;
    const gift_token = useParams().gift_token;
    const mode = useParams().mode;
    useEffect(() => {
        if(document.body.offsetWidth <= 1200){
            setSidebarVisible(false);
            collapse();

        }else{
            setSidebarVisible(true);
            expand();
        }
    },[size,chaptersid]);
    const SideMenuData = useMemo(() => {
        if(chaptersData && (!chaptersData.zhuanlan_locked || chaptersData.free) && !isWxBrowser()){
            return(
                [
                    {
                        icon:<WechatOutlined />,
                        name:"wechat_share",
                        onClick:function (){
                            Modal.confirm({
                                title: "文章试读赠送",
                                content:
                                    <div style={{position:"relative",left:"-20px"}}>
                                        <div style={{position:"absolute",right:"-10px",top:"-25px",fontSize:"12px",color:"darkgrey"}}>{chaptersData.free === 1 ? <div>此文章可免费分享</div> : <div>赠送次数：{giftCnt}/3</div>}</div>
                                        <div>
                                            <img  style={{width:"100%"}} src={chaptersData.zhuanlan_img}/>
                                            <div style={{textAlign:"center",color:"gray"}}>{chaptersData.zhuanlan_name}</div>
                                            <div style={{textAlign:"center",fontSize:"20px"}}>{chaptersData.name}</div>
                                        </div>
                                        <div style={{fontSize:"12px",color:"darkgrey",lineHeight:"13px",marginTop:"20px"}}>注：用户点击"确定"后，可使用手机微信扫描二维码，并通过右上角转发功能，分享给您的好友。</div>
                                    </div>,
                                okType: "primary",
                                okText: "确定",
                                cancelText: "取消",
                                onOk: async function () {
                                    if(giftCnt >= 3 && chaptersData.free !== 1){
                                        Modal.error({
                                            title:"您的赠送次数已达上限",
                                            okType: "primary",
                                            okText:"确定"
                                        })
                                    }else{
                                        await httpclient.addGift('chapter',chaptersData.id).then((res) => {
                                            if(res){
                                                if(chaptersData.free !== 1){setGiftCnt(giftCnt+1);}
                                                PopModal({
                                                    body:<div>
                                                        <img src={"data:image/png;base64,"+res.qrCode}/>
                                                        <div  style={{textAlign:"center",fontSize:"12px",color:"darkgrey",lineHeight:"13px",marginTop:"-10px"}}>请使用手机微信扫描二维码分享给您的好友</div>
                                                    </div>,
                                                    width: 300,
                                                    closeButton:true,
                                                })
                                            }else{
                                                Modal.destroyAll();
                                            }

                                        })
                                    }
                                },
                            })
                        },
                    },
                    {
                        icon:<CommentOutlined />,
                        name:"toComments",
                        onClick:function () {
                            let commentsDom = document.getElementsByClassName('chapter-comments')[0];
                            //let chapterDom = document.getElementsByClassName('chapter')[0]; //
                            scrollRef.current.scrollTo(0,commentsDom.offsetTop);
                            // chapterDom.scrollTo(0,commentsDom.offsetTop);
                        },
                    },
                    // {
                    //     icon:<StarOutlined />,
                    //     name:"test",
                    //     onClick:"",
                    // },
                    {
                        icon:<Tooltip title={<div style={{color:'darkgrey',fontSize:'12px'}}>回到顶部</div>} color="white"><FontAwesomeIcon icon={faChevronUp}/></Tooltip>,
                        name:"toTop",
                        onClick:function () {
                            //let chapterDom = document.getElementsByClassName('chapter')[0]; //
                            scrollRef.current.scrollTop = 0;
                            // chapterDom.scrollTop = 0;
                        },
                    }
                ]
            )
        }else  return(
            [
                {
                    icon:<CommentOutlined />,
                    name:"toComments",
                    onClick:function () {
                        let commentsDom = document.getElementsByClassName('chapter-comments')[0];
                        //  let chapterDom = document.getElementsByClassName('chapter')[0]; //
                        scrollRef.current.scrollTo(0,commentsDom.offsetTop);
                        // chapterDom.scrollTo(0,commentsDom.offsetTop);
                    },
                },
                // {
                //     icon:<StarOutlined />,
                //     name:"test",
                //     onClick:"",
                // },
                {
                    icon:<Tooltip title={<div style={{color:'darkgrey',fontSize:'12px'}}>回到顶部</div>} color="white"><FontAwesomeIcon icon={faChevronUp}/></Tooltip>,
                    name:"toTop",
                    onClick:function () {
                        //  let chapterDom = document.getElementsByClassName('chapter')[0]; //
                        scrollRef.current.scrollTop = 0;
                        //  chapterDom.scrollTop = 0;
                    },
                }
            ]
        )
    },[chaptersData,giftCnt,scrollRef]);
    useEffect(() => {
        setIsLoading(true);
        if(mode === 'receive'){
            httpclient.getGiftData(gift_token).then((gift) => {
                if(gift.mode === 'free'){
                    httpclient.getChaptersPreviewData(gift.chapter.id).then((data) => {
                        setChaptersData(data);
                        setIsLoading(false);
                        document.getElementsByClassName('chapter')[0].scrollTop = 0;
                        httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                            setZhuanlanData(data);
                        })
                        if(comment_id){
                            let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                            let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                            console.log(commentDom.getBoundingClientRect().top);
                            chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                        }
                    });
                    PopModal({
                        title:<div style={{textAlign:'center',padding:'10px'}}>{gift.user} 邀请你试读免费文章</div>,
                        body:<div>
                            <img style={{width:'100%'}} src={gift.zhuanlan.image}/>
                            <div style={{textAlign:"center",color:"gray",fontSize:"14px"}}>{gift.zhuanlan.name}</div>
                            <div style={{textAlign:"center",fontSize:"20px"}}>{gift.chapter.name}</div>
                        </div>,
                        borderRadius:20,
                        width:300,
                        closeButtonPosition:"bottom",
                    })
                }else{
                    if(localStorage.token){
                        httpclient.receiveGift(gift_token).then((res) => {
                            let popMsg;
                            if(res.errMsg === '您已拥有该内容，请勿重复领取'){
                                popMsg = <div style={{textAlign:"center",fontSize:"12px",color:"orange"}}>您已拥有该内容，请勿重复领取</div>
                                httpclient.getChaptersData(gift.chapter.id).then((data) => {
                                    setChaptersData(data);
                                    setIsLoading(false);
                                    if(data.gift_cnt)setGiftCnt(data.gift_cnt);
                                    document.getElementsByClassName('chapter')[0].scrollTop = 0;
                                    httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                        setZhuanlanData(data);
                                    })
                                    if(comment_id){
                                        let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                        let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                        console.log(commentDom.getBoundingClientRect().top);
                                        chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                                    }
                                });
                            }else if(res.errMsg === '您已试读过该专栏的内容，请付费解锁完整内容'){
                                popMsg = <div style={{textAlign:"center",fontSize:"12px",color:"orange"}}>您已试读过该专栏的内容，请付费解锁完整内容</div>
                                httpclient.getChaptersPreviewData(gift.chapter.id).then((data) => {
                                    setIsPreview(true);
                                    setChaptersData(data);
                                    setIsLoading(false);
                                    document.getElementsByClassName('chapter')[0].scrollTop = 0;
                                    httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                        setZhuanlanData(data);
                                    })
                                    if(comment_id){
                                        let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                        let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                        console.log(commentDom.getBoundingClientRect().top);
                                        chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                                    }
                                });
                            }else if(res.errMsg === '该礼物已被领取'){
                                popMsg = <div style={{textAlign:"center",fontSize:"12px",color:"#fa6246"}}>该礼物已被领取</div>
                                httpclient.getChaptersPreviewData(gift.chapter.id).then((data) => {
                                    setIsPreview(true);
                                    setChaptersData(data);
                                    setIsLoading(false);
                                    document.getElementsByClassName('chapter')[0].scrollTop = 0;
                                    httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                        setZhuanlanData(data);
                                    })
                                    if(comment_id){
                                        let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                        let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                        console.log(commentDom.getBoundingClientRect().top);
                                        chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                                    }
                                });
                            }else{
                                popMsg = <div style={{textAlign:"center",fontSize:"12px",color:"#5995DA"}}>恭喜您成功领取</div>
                                httpclient.getChaptersData(gift.chapter.id).then((data) => {
                                    setChaptersData(data);
                                    setIsLoading(false);
                                    if(data.gift_cnt)setGiftCnt(data.gift_cnt);
                                    document.getElementsByClassName('chapter')[0].scrollTop = 0;
                                    httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                        setZhuanlanData(data);
                                    })
                                    if(comment_id){
                                        let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                        let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                        console.log(commentDom.getBoundingClientRect().top);
                                        chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                                    }
                                });
                            }
                            PopModal({
                                title:<div style={{textAlign:'center',padding:'10px'}}>{gift.user} 赠送给你一篇文章</div>,
                                body:<div>
                                    <img style={{width:'100%'}} src={gift.zhuanlan.image}/>
                                    <div style={{textAlign:"center",color:"gray",fontSize:"14px"}}>{gift.zhuanlan.name}</div>
                                    <div style={{textAlign:"center",fontSize:"20px"}}>{gift.chapter.name}</div>
                                    {popMsg}
                                </div>,
                                borderRadius:20,
                                width:300,
                                closeButtonPosition:"bottom",
                            })
                        })
                    }else{
                        httpclient.getChaptersPreviewData(gift.chapter.id).then((data) => {
                            setIsPreview(true);
                            setChaptersData(data);
                            setIsLoading(false);
                            document.getElementsByClassName('chapter')[0].scrollTop = 0;
                            httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                setZhuanlanData(data);
                            })
                            if(comment_id){
                                let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                console.log(commentDom.getBoundingClientRect().top);
                                chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                            }
                        });
                        PopModal({
                            title:<div style={{textAlign:'center',padding:'10px'}}>{gift.user} 赠送给你一篇文章</div>,
                            body:<div>
                                <img style={{width:'100%'}} src={gift.zhuanlan.image}/>
                                <div style={{textAlign:"center",color:"gray",fontSize:"14px"}}>{gift.zhuanlan.name}</div>
                                <div style={{textAlign:"center",fontSize:"20px"}}>{gift.chapter.name}</div>
                            </div>,
                            closeText:gift.status === 'claimed' ?
                                <div  style={{textAlign:"center",fontSize:"12px",color:"#fa6246",cursor:"pointer"}}>该礼物已被领取，请登陆并解锁后阅读</div>
                                : <div style={{textAlign:"center",fontSize:"12px",color:"#5995DA",cursor:"pointer"}}>请登陆并领取该礼物</div>,
                            borderRadius:20,
                            width:300,
                            closeButtonPosition:"bottom",
                            onClose:function (){
                                PopDrawer({
                                    title:"登录/注册",
                                    body:<div>
                                        <LoginDrawer></LoginDrawer>
                                    </div>,
                                    footer:<div>暂不登录</div>,
                                })
                            },
                        })
                    }
                }
            })

        }else if(mode === 'share'){
            if(isWxBrowser()){
                httpclient.getWxConfigParams(window.location.href.split('#')[0]).then((res) => {
                    if(res){
                        httpclient.getChaptersData(chaptersid).then((data) => {
                            setChaptersData(data);
                            setIsLoading(false);
                            if(+data.gift_cnt >= 0) {
                                setGiftCnt(data.gift_cnt);
                            }
                            document.getElementsByClassName('chapter')[0].scrollTop = 0;
                            httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                                setZhuanlanData(data);
                            })
                            if(comment_id){
                                let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                                let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                                chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                            }
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                                appId: res.appId, // 必填，公众号的唯一标识
                                timestamp: res.timeStamp, // 必填，生成签名的时间戳
                                nonceStr: res.nonceStr, // 必填，生成签名的随机串
                                signature: res.signature,// 必填，签名
                                jsApiList: ['updateAppMessageShareData'],// 必填，需要使用的 JS 接口列表
                            });
                            wx.ready(function () {//需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData({
                                    title: data.zhuanlan_name, // 分享标题
                                    desc: data.name, // 分享描述
                                    link: 'https://icdesign.com/chapters/'+chaptersid+'/receive/'+gift_token, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                    imgUrl: "https://cdn.datongbei.com/icdesign-1660701418413da614d6gc7dg7.jpeg?imageView2/0/format/jpg/q/15|imageslim", // 分享图标
                                    success: function () {
                                        // 设置成功
                                    }
                                })
                            });
                            wx.error(function(res) {
                                alert(res);
                            });
                        })
                    }

                })
            }
        }else{
            httpclient.getChaptersData(chaptersid).then((data) => {
                setChaptersData(data);
                setIsLoading(false);
                if(+data.gift_cnt >= 0) {
                    setGiftCnt(data.gift_cnt);
                }
                document.getElementsByClassName('chapter')[0].scrollTop = 0;
                httpclient.getZhuanlansData(data.zhuanlan_node_id).then((data) => {
                    setZhuanlanData(data);
                })
                if(comment_id){
                    let commentDom = document.getElementsByClassName(`comment-item ${comment_id}`)[0];
                    let chapterDom = document.getElementsByClassName('scrollbar-content')[0];
                    chapterDom.scrollTo(0,commentDom.getBoundingClientRect().top - 50);
                }
            })
        }
    },[chaptersid,gift_token,mode,localStorage.token]);
    useEffect(() => {
        if(chaptersData){
            const chapterscontent  = chaptersData.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*?(?:style=['"]([^'"]+)['"])?[^>]*>/gi, function (match, capture,style) {
                style = style || "";
                return `<img width="100%" style="${style}" src="${capture}|imageMogr2/thumbnail/1000x|watermark/4/text/d3d3LmljZGVzaWduLmNvbSDlh7rlk4E=/fontsize/650/fill/Z3JheQ==/dissolve/10/rotate/-45/uw/500/uh/250/resize/1"/>`;
            });
            setChaptersContent(chapterscontent);
        }
    },[chaptersData]);
    const collapse = () => {
        let sidebarDom = document.getElementsByClassName('sidebar')[0];
        let chapterDom = document.getElementsByClassName('chapter')[0];
        let sidemenuDom = document.getElementsByClassName('sidemenu')[0];
        let chapterContentDom = document.getElementsByClassName('chapter-content-wrapper')[0];
        if(sidebarDom && chapterContentDom &&chapterDom && sidemenuDom){
            if(document.body.offsetWidth < 600){
                sidebarDom.style.left = `-${document.body.offsetWidth}px`;
            }else{
                sidebarDom.style.left = `-${sidebarDom.offsetWidth}px`;
            }
            chapterDom.style.left = '0px';
            chapterDom.style.width = '100%';
            sidemenuDom.style.left = `calc(50% + ${chapterContentDom.offsetWidth/2+20}px)`;
        }
    };
    const expand = () => {
        let sidebarDom = document.getElementsByClassName('sidebar')[0];
        let chapterDom = document.getElementsByClassName('chapter')[0];
        let chapterContentDom = document.getElementsByClassName('chapter-content-wrapper')[0];
        let sidemenuDom = document.getElementsByClassName('sidemenu')[0];
        if(sidebarDom && chapterContentDom &&chapterDom && sidemenuDom){
            sidebarDom.style.left = '0px';
            if(document.body.offsetWidth >= 1200){
                chapterDom.style.left = `${sidebarDom.offsetWidth}px`;
                chapterDom.style.width = `calc(100% - ${sidebarDom.offsetWidth}px)`;
                sidemenuDom.style.left = `calc(${sidebarDom.offsetWidth/2}px + 50% + ${chapterContentDom.offsetWidth/2+10}px)`;
            }
        }
    }
    const chaptercontent = useMemo( () => {
        return(
            <div>
                {!!chaptersData && SideMenu(SideMenuData)}
                {!!chaptersData && mode === 'share' && isWxBrowser() &&
                <div className="gift-notification">
                    <Popover content={<div style={{color:'grey'}}>分享给您的朋友</div>} visible={true}
                             placement="bottomRight" >
                    </Popover>
                </div>
                }
                {!!chaptersData &&
                <div className="chapter-name-wrapper">
                    <div className="chapter-name">{chaptersData.name}</div>
                </div>}
                {!!chaptersData && chaptersContent && <div className="chapter-content-wrapper">
                    <div className={isPreview ? "chapter-content-preview" : "chapter-content"}>
                        <div className="notelix-enabled" ref={element => {
                            if (+chaptersData.id !== +chaptersid || !localStorage.token) {
                                return;
                            }
                            window.NotelixReady.then(() => {
                                if (element) {
                                    window.NotelixAPI.loadAllAnnotationsData();
                                }
                            });
                        }
                        }>
                            <ZhuanlanRender
                                token={localStorage.token}
                                drmVideoConfig={{
                                    getVideoPlayInfo: (accessId) => {
                                        return httpclient.getVideoPlayInfo(accessId);
                                    },
                                }}
                                markdown={{
                                    content: chaptersContent
                                }}
                            />
                        </div>
                    </div>
                    {!!isPreview &&
                    <div className="preview-notification">
                        <div>……</div>
                        {localStorage.token ? <div>完整内容请至网站购买后阅读</div>:<div>完整内容请登陆并解锁后阅读</div>}
                    </div>}
                </div>}
            </div>
        )
    },[chaptersData,chaptersid,giftCnt,chaptersContent]);
    return (
        <div ref={setTarget}>
            {!!zhuanlanData && (sidebarVisible ? <div className="sidebar-collapse-button" onClick={() => {setSidebarVisible(false);collapse()}}><MenuFoldOutlined /></div> :
                    <div className="sidebar-expand-button" onClick={() => {
                        setSidebarVisible(true);expand()
                    }}><MenuUnfoldOutlined /></div>
            )}
            {!!chaptersData && Sidebar(zhuanlanData,chaptersData.zhuanlan_node_id)}
            {!!isLoading && zhuanlanData &&<div className="chapter" style={{textAlign:'center',height:'calc(100vh - 90px)',overScroll:'hidden'}}><Spin tip='加载中...' style={{position:'relative',top:'50%',transform:'translateY(-50%)',color:'darkgray'}} size={"large"}/></div>}
            {!! isLoading && !zhuanlanData &&<Spin tip='加载中...' style={{position:'absolute',left:'50%',top:'50%',transform:'translateY(-50%) translateX(-50%)',color:'darkgray'}} size={"large"}/>}
            <div style={{position:'relative',top:'50px'}}>
                <div className="chapter">
                    <SimpleScrollBar ref={scrollRef} behavior="smooth" key={chaptersid}>
                        {!!(chaptersData && !isLoading) && chaptercontent}
                        <div className="chapter-comments">{Comments(chaptersData,'chapter',chaptersid,default_expand_id,userInfo,updateUserInfo)}</div>
                    </SimpleScrollBar>
                </div>
            </div>
        </div>
    )
}

export default Chapters;
