import ZhuanlanRender from "zhuanlan-render";
import httpclient from "../../service/httpclient";
import "./RichText.scss";
import useKaTeX from "../../hooks/useKaTeX";
import { useRef } from "react";

function RichText({ richText, ...props }) {
  const { type, content } = richText;
  let inner = <></>;
  switch (type) {
    case "MD":
      inner = <RichTextForMD content={content} />;
      break;
    case "HTML":
      inner = <RichTextForHTML content={content} />;
      break;
    case "STRING":
      inner = <RichTextForString content={content} />;
      break;
    default:
      break;
  }
  return <div {...props}>{inner}</div>;
}

function RichTextForHTML({ content }) {
  const ref = useRef();
  useKaTeX("ck-math-tex", content, ref);
  return (
    <div
      ref={ref}
      className="ckeditor-display-container"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
function RichTextForString({ content }) {
  return <div>{content}</div>;
}
function RichTextForMD({ content }) {
  return (
    <ZhuanlanRender
      markdown={{ content }}
      drmVideoConfig={{
        getVideoPlayInfo: (accessId) => httpclient.getVideoPlayInfo(accessId),
      }}
    />
  );
}

export default RichText;
